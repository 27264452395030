export const useStyles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  topSpace: {
    height: theme.spacing(11),
  },
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',

  },
  formLogin: {
    backgroundColor: '#fff',
    fontFamily: 'Roboto Condensed,sans-serif',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 8,
    marginBottom: theme.spacing(3),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(2, 0, 0),
  },
  title: {
    textTransform: 'uppercase',
    color: '#1f3a4e',
    fontSize: 40,
    fontFamily: 'Bebas Neue, cursive !important',
  },
  titleSmall: {
    fontSize: 16,
    color: '#1f3a4e',
  },
  forgotPasswordLink: {
    marginTop: theme.spacing(2),
    fontSize: 16,
  }
})