export const useStyles = theme => ({
  comingSoon: {
    backgroundColor: '#eebf2f',
    fontFamily: 'Roboto Condensed,sans-serif',
  },
  paper: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#eebf2f',
    borderRadius: 8,
  },
  avatar: {
    width: 180,
    height: 180,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#1f3a4e',
    color: '#fff'
  },
  title: {
    textTransform: 'uppercase',
    color: '#1f3a4e',
    fontSize: 40,
    fontFamily: 'Bebas Neue, cursive !important',
  },
  titleSmall: {
    // textTransform: 'uppercase',
    fontSize: 18,
    color: '#1f3a4e',
  },
  titleMedium: {
    textTransform: 'uppercase',
    fontSize: 16,
    color: '#1f3a4e',
  },
  rodape: {
    fontSize: 13,
    color: '#1f3a4e',
  }
});
