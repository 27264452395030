
import React from 'react';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from '../assets/estilos/pages/home';
import { Container, Typography, Grid, Snackbar, Breadcrumbs, Button } from '@material-ui/core';
import { Card, CardActionArea, CardContent, CardMedia } from '@material-ui/core';
import Services from "../Services";
import PropTypes from 'prop-types';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppMenuLogged from '../components/MenuUser';
import AppMenu from '../components/Menu';
import Footer from '../components/Footer';
import MuiAlert from '@material-ui/lab/Alert';
import { createMuiTheme, ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { If } from 'react-if';
import {
    BrowserView,
} from "react-device-detect";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#eebf2f',
            main: '#eebf2f',
            dark: '#eebf2f',
            contrastText: '#1f3a4e',
        },
        secondary: {
            light: '#1f3a4e',
            main: '#1f3a4e',
            dark: '#1f3a4e',
            contrastText: '#fff',
        },
    },
});

class Promotion extends React.Component {

    state = {
        redirect: '',
        openAlert: false,
        alertMessage: '',
        alertStatus: 'info',
        logged: false,
        loadingForm: false,
        promocoes: [],

    }

    componentDidMount() {
        let usuario = JSON.parse(localStorage.getItem('user')) || '';
        let carrinho = localStorage.getItem('carrinho') || '';
        let token = JSON.parse(localStorage.getItem('token')) || '';

        if (usuario !== '' && token !== '') {
            this.setState({ logged: true });
        }

        document.getElementById("top").scroll(0, 0)
        Services.promocoes(this);

    }

    responsePromocoes(response) {
        if (response.success === 'true') {
            this.setState({ promocoes: response.promocoes })
        }
        else {
            this.setState({ openAlert: true })
            this.setState({ alertMessage: "Opa!" + response.msg })
            this.setState({ alertStatus: 'error' })
            return
        }
    }

    render() {
        const { classes } = this.props;
        return (<div className={classes.root} id="top">
            <ThemeProvider theme={theme}>
                <If condition={this.state.logged === false}>
                    <AppMenu />
                </If>
                <If condition={this.state.logged === true}>
                    <AppMenuLogged />
                </If>
                <main>
                    <div className={classes.topSpace} />

                    <Container maxWidth="lg" className={classes.container}>
                        <Link to='/home'>
                            <Button color="secondary" startIcon={<ArrowBackIcon />} variant='contained'>
                                Voltar
                            </Button>
                        </Link>
                    </Container>

                    <Container maxWidth="lg" className={classes.container}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/home' >
                                Home
                            </Link>
                            <Link to="/promocoes">
                                Promoções
                            </Link>
                        </Breadcrumbs>
                    </Container>

                    <div>
                        {/* <Carousel
                            autoPlay={this.state.autoPlay}
                            timer={this.state.timer}
                            animation={this.state.animation}
                            indicators={this.state.indicators}
                            timeout={this.state.timeout}
                            navButtonsAlwaysVisible={this.state.navButtonsAlwaysVisible}
                            navButtonsAlwaysInvisible={this.state.navButtonsAlwaysInvisible}
                        >
                            {
                                this.state.promocoes.map((item, index) => {
                                    return <div key={index} className={classes.bannerCaroussel}>
                                        <img style={{ width: '100%' }} src={item.foto_grande} />
                                    </div>
                                })
                            }
                        </Carousel> */}

                        <Container maxWidth="lg" className={classes.container}>
                            <Grid className={classes.gridProducts} container spacing={2}>
                                <Grid item xs={12} lg={12}>
                                    <Typography component="h1" variant="h5" align="center" className={classes.title}>
                                        Promoções
                            </Typography>
                                </Grid>
                                {this.state.promocoes.map((promocoes) => {
                                    return <Grid item xs={12} lg={4} key={promocoes.id}>
                                        <Card raised={true}>
                                            <Link to={'/promocao/' + promocoes.id}><CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    // alt="Contemplative Reptile"
                                                    height="auto"
                                                    image={promocoes.foto_grande}
                                                // title="Contemplative Reptile"
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom className="productTitle" variant="h5" component="h2">
                                                        {promocoes.titulo}
                                                    </Typography>
                                                    <Typography variant="subtitle2" className="productDescription" component="p">
                                                        {promocoes.texto}
                                                    </Typography>

                                                </CardContent>
                                            </CardActionArea></Link>
                                        </Card>
                                    </Grid>
                                })}
                            </Grid>
                        </Container>
                    </div>

                    <Snackbar open={this.state.openAlert} autoHideDuration={5000} onClose={this.handleCloseAlert}>
                        <Alert severity={this.state.alertStatus} onClose={this.handleCloseAlert}>
                            {this.state.alertMessage}
                        </Alert>
                    </Snackbar>


                </main>

                <BrowserView>
                    <Footer />
                </BrowserView>


            </ThemeProvider>
        </div>
        )
    }
}


Promotion.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(Promotion);