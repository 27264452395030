import React from 'react';
import './App.scss';
import AppRouter from './Router';
import ReactGA from 'react-ga';

const trackingId = "G-CTTPQ6RXD9"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);


function App() {
  return (
    <div className="App" id="top">
      <AppRouter />
    </div>
  );
}

export default App;
