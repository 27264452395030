import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from '../assets/estilos/pages/home';
import { Container, Typography, Button, Grid, TextField, FormControl, Select, MenuItem, Snackbar, InputLabel, InputAdornment } from '@material-ui/core';
import { Card, CardActionArea, CardActions, CardContent, CardMedia } from '@material-ui/core';
import { Pagination } from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import AppMenu from '../components/Menu';
import AppMenuLogged from '../components/MenuUser';
import Footer from '../components/Footer';
import Services from "../Services";
import MuiAlert from '@material-ui/lab/Alert';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
import { If } from 'react-if';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#eebf2f',
            main: '#eebf2f',
            dark: '#eebf2f',
            contrastText: '#1f3a4e',
        },
        secondary: {
            light: '#1f3a4e',
            main: '#1f3a4e',
            dark: '#1f3a4e',
            contrastText: '#fff',
        },
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class Products extends React.Component {

    state = {
        nome: '',
        email: '',
        error: '',
        action: 'criar',
        openAlert: false,
        redirect: '',
        produtos: [],
        categorias: [],
        marcas: [],
        // paginas: 1,
        logged: false,
        busca: '',
        display: 'none',
        display2: 'flex',
        noprodutos: localStorage.getItem('noprodutos') || '',
        displaySearch: 'none',
        exibeBusca: localStorage.getItem('busca') || '',
    }


    componentDidMount() {

        let usuario = JSON.parse(localStorage.getItem('user')) || '';
        let token = JSON.parse(localStorage.getItem('token')) || '';

        if (usuario !== '' && token !== '') {
            this.setState({ logged: true });
        }

        this.exibeProdutos();
        Services.produtosMarcas(this);
        Services.produtosCategorias(this);

        let carrinho = localStorage.getItem('carrinho') || '';

        if (carrinho === '') {
            const cart = [{
                id_carrinho: "",
                id_produto: "",
                qntd: "",
                action: "criar"
            }];
            Services.carrinho(this, usuario.id, token, cart[0]);
        }
    }

    componentWillUnmount() {
        this.limparFiltros();
    }

    exibeProdutos() {
        let categorias = localStorage.getItem('id_categoriaProduto') || '';
        let marcas = localStorage.getItem('id_marcasProduto') || '';
        let busca = localStorage.getItem('busca') || '';
        let filtro = localStorage.getItem('filtro') || '';
        let pagina = localStorage.getItem('pagina') || 1;

        const values = [{
            id_marca: marcas,
            id_categoria: categorias,
            busca: busca,
            pagina: pagina,
            filtro: filtro,
            valor_ini: '',
            valor_fim: '',
        }];

        Services.produtos(this, values[0]);
    }

    responseProdutos(response) {
        if (response.success === 'true') {
            this.setState({ produtos: response.produtos });
            this.setState({ paginas: parseInt(response.total_paginas) })

            if (response.total === 0) {
                this.setState({ noprodutos: 'Não há produtos cadastrados nesta categoria' })
                localStorage.setItem('noprodutos', 'Não há produtos cadastrados nesta categoria');
            }
            else {
                this.setState({ noprodutos: '' })
            }

        }
        else {
            localStorage.setItem('noprodutos', response.error.msg);
            this.setState({ noprodutos: 'Não há produtos cadastrados nesta categoria' })
            return
        }
    }

    responseProdutosCategorias(response) {
        if (response.success === 'true') {
            this.setState({ categorias: response.produtos_categorias });
        }
        else {
            localStorage.setItem('noprodutos', 'Não há produtos cadastrados nesta categoria');
            this.setState({ noprodutos: 'Não há produtos cadastrados nesta categoria' })
            return
        }
    }

    responseProdutosMarcas(response) {
        if (response.success === 'true') {
            this.setState({ marcas: response.produtos_marcas });
        }
        else {
            this.setState({ marcas: [] });
        }
    }

    limparFiltros = () => {
        localStorage.removeItem('id_categoriaProduto');
        localStorage.removeItem('id_marcasProduto');
        localStorage.removeItem('busca');
        localStorage.removeItem('filtro');
        localStorage.removeItem('pagina');
        window.location.reload();
    }

    onChangeBusca = (e) => {
        this.setState({ busca: e.target.value });
    }

    buscaProduto = () => {
        localStorage.removeItem('busca');
        localStorage.removeItem('id_categoriaProduto');
        localStorage.removeItem('id_marcasProduto');
        localStorage.removeItem('filtro');
        localStorage.removeItem('pagina');
        localStorage.setItem('busca', this.state.busca);
        this.setState({ exibeBusca: this.state.busca })
        this.exibeProdutos();
    }

    buscaMobile = (e) => {
        this.setState({ busca: e.target.value });
        localStorage.removeItem('busca');
        localStorage.removeItem('id_categoriaProduto');
        localStorage.removeItem('id_marcasProduto');
        localStorage.removeItem('filtro');
        localStorage.removeItem('pagina');
        localStorage.setItem('busca', e.target.value);
        this.setState({ exibeBusca: e.target.value })
        this.exibeProdutos();
    }

    salvaCateg = (e) => {
        localStorage.removeItem('busca');
        this.setState({ busca: '' });
        localStorage.removeItem('id_categoriaProduto');
        localStorage.removeItem('id_marcasProduto');
        localStorage.setItem('id_categoriaProduto', e);
        this.exibeProdutos();
    }

    pagination = (event, value) => {
        localStorage.setItem('pagina', value);
        this.exibeProdutos();
    }

    filtro = e => {
        localStorage.setItem('filtro', e.target.value);
        this.exibeProdutos();
    }

    handleCloseAlert = () => {
        this.setState({ openAlert: false })

        if (this.state.action === 'add') {
            this.setState({ redirect: 'sacola' })
            return
        }

        this.setState({ alertMessage: '' })
        this.setState({ alertStatus: '' })
        window.location.reload();
    }

    addCarrinho = (produto) => {
        let usuario = JSON.parse(localStorage.getItem('user')) || '';
        let carrinho = localStorage.getItem('carrinho') || '';
        let token = JSON.parse(localStorage.getItem('token')) || '';
        this.setState({ action: 'add' })

        const cart = [{
            id_carrinho: carrinho,
            id_produto: produto,
            qntd: 1,
            action: "add_produto"
        }];

        Services.carrinho(this, usuario.id, token, cart[0]);
    }

    responseCarrinho(response) {
        if (response.success === 'true') {
            if (this.state.action === 'criar') {
                localStorage.removeItem('carrinho');
                localStorage.setItem('carrinho', response.id_carrinho);
                return
            }
            if (this.state.action === 'add') {
                this.setState({ openAlert: true })
                this.setState({ alertMessage: "O produto foi adicionado ao pedido!" })
                this.setState({ alertStatus: 'success' })
                return
            }
        }
        if (response.error === 'true' && response.type === 'token_invalido') {
            localStorage.setItem('token_invalido', 'ok')
            this.setState({ redirect: '#' });
            return
        }
        if (response.error === 'true' && response.type === 'token_invalido') {
            localStorage.setItem('token_invalido', 'ok')
            this.setState({ redirect: '#' });
            return
        }
    }

    mostraFiltros = () => {
        this.setState({ display: 'inherit' });
        this.setState({ display2: 'none' });
    }

    escondeFiltros = () => {
        this.setState({ display2: 'flex' });
        this.setState({ display: 'none' });
    }

    scrollToComponent = () => {
        document.getElementById("produtos").scroll(0, 0)
    }

    render() {

        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }
        const { classes } = this.props;
        return (<div className={classes.root} onWheel={(e) => this.setState({ displaySearch: 'inherit' })}>
            <ThemeProvider theme={theme}>

                <If condition={this.state.logged === false}>
                    <AppMenu />
                </If>
                <If condition={this.state.logged === true}>
                    <AppMenuLogged />
                </If>

                <main>
                    <div className={classes.topSpace} />
                    <BrowserView>
                        <div className='bgSearch' style={{ display: this.state.displaySearch }}>
                            <Container maxWidth="lg" className={classes.container}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-evenly"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} lg={12}>
                                        <Typography component="h1" variant="h5" align="center" className='title2' paragraph>
                                            Tchê! Encontra tua bebida favorita
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={8} lg={4}>
                                        <TextField
                                            fullWidth
                                            value={this.state.busca}
                                            onChange={this.onChangeBusca}
                                            type="text"
                                            name="busca"
                                            className="textfieldBusca" />

                                    </Grid>
                                    <Grid item xs={4} lg={2}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={this.buscaProduto}
                                            className="w-100 borderButton"
                                            endIcon={<SearchIcon />}>Buscar</Button>
                                    </Grid>
                                </Grid>

                            </Container>
                        </div>
                    </BrowserView>

                    <Container maxWidth="lg" className={classes.container}>
                        <Grid className={classes.gridProducts} container spacing={2}
                            direction="row"
                            justify="center"
                            alignItems="center">
                            <Grid item xs={12} lg={12}>
                                <Typography component="h1" variant="h5" align="center" className={classes.title}>
                                    Produtos
                            </Typography>
                            </Grid>

                            <Grid item lg={3}></Grid>

                            <Grid item xs={12} lg={6} style={{ display: this.state.display2 }}>
                                <Button
                                    onClick={this.mostraFiltros}
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    endIcon={<ArrowDownwardIcon />}>Filtros</Button>
                            </Grid>

                            <Grid item xs={12} lg={6} style={{ display: this.state.display }}>
                                <Button
                                    onClick={this.escondeFiltros}
                                    color="secondary"
                                    variant="contained"
                                    fullWidth
                                    endIcon={<ArrowUpwardIcon />}>Esconder Filtros</Button>
                            </Grid>

                            <Grid item lg={3}></Grid>
                        </Grid>

                        <Grid className={classes.gridProducts} container spacing={2} style={{ display: this.state.display }}
                            direction="row"
                            justify="center"
                            alignItems="center">

                            <Grid item xs={12} lg={12}>
                                <MobileView>
                                    <TextField
                                        fullWidth
                                        value={this.state.busca}
                                        onChange={this.buscaMobile}
                                        color="secondary"
                                        type="text"
                                        name="busca"
                                        label="Buscar produto"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start" >
                                                <SearchIcon color="secondary" />
                                              </InputAdornment>
                                            ),
                                          }} />
                                </MobileView>
                            </Grid>

                            <Grid item container xs={12} lg={12} spacing={2} align="center"
                            direction="row"
                            justify="center"
                            alignItems="center">
                                {this.state.categorias.map((categorias) => {
                                    return <Grid item xs={6} lg={2} key={categorias.id} >
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            size="large"
                                            color="secondary"
                                            id="filter_categ"
                                            className={classes.button}
                                            value={categorias.id}
                                            onClick={() => this.salvaCateg(categorias.id)}
                                        >
                                            {categorias.titulo}
                                        </Button>
                                    </Grid>
                                })}
                            </Grid>

                            <Grid item container xs={12} lg={12} align="center" direction="row"
                            justify="center"
                            alignItems="center">
                            <Grid item xs={12} lg={6}>
                                <Button onClick={this.limparFiltros} startIcon={<DeleteIcon/>} color="primary" variant='contained' fullWidth>
                                    Limpar filtros
                                    </Button>
                            </Grid>
                            </Grid>



                        </Grid>

                        <Grid className={classes.gridProducts} container
                            spacing={2}
                            direction="row"
                            justify="center"
                            alignItems="center">

                            <Grid item xs={6} lg={9}>
                            </Grid>

                            <Grid item xs={6} lg={3} className="mt-10">
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="ordenar">Ordenar</InputLabel>
                                    <Select
                                        label="Ordenar"
                                        onChange={this.filtro}
                                        value={localStorage.getItem('filtro')}
                                        inputProps={{
                                            name: "ordenar",
                                            id: "ordenar"
                                        }}
                                        fullWidth
                                    >
                                        <MenuItem value={''}>Ordenar</MenuItem>
                                        <MenuItem value={'destaque'}>Em destaque</MenuItem>
                                        <MenuItem value={'a-z'}>A - Z</MenuItem>
                                        <MenuItem value={'z-a'}>Z - A</MenuItem>
                                        <MenuItem value={'valor_menor'}>Menor preço</MenuItem>
                                        <MenuItem value={'valor_maior'}>Maior preço</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} lg={12}>
                                <If condition={this.state.exibeBusca != ''}>
                                    <Typography component="h3" variant="subtitle2" align="center" paragraph>
                                        Exibindo resultados para: {this.state.exibeBusca}
                                    </Typography>
                                </If>
                            </Grid>

                            <Grid item xs={12} lg={12}>
                                <Typography component="h3" variant="subtitle2" align="center" paragraph>
                                    {this.state.noprodutos}
                                </Typography>
                            </Grid>

                            {this.state.produtos.map((produtos) => {
                                return <Grid item xs={6} lg={3} key={produtos.id}>
                                    <Card raised={true}>
                                        <Link to={'/produto/' + produtos.id}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="auto"
                                                    image={produtos.foto_pequena}
                                                />
                                                <CardContent>
                                                    <BrowserView >
                                                        <Typography gutterBottom className="productTitle" variant="h5" component="h2" noWrap>
                                                            {produtos.titulo}
                                                        </Typography>
                                                        <Typography variant="caption" className="productDescription" component="p">
                                                            {produtos.marca} - {produtos.categoria}
                                                        </Typography>

                                                        <If condition={produtos.status_promo === 1 || produtos.status_promo === '1'}>
                                                            <Typography className="productPrice" variant="h6">
                                                                <span className="oldPrice">{produtos.preco}</span>
                                                            R$ {produtos.preco_promo}
                                                            </Typography>
                                                        </If>
                                                        <If condition={produtos.status_promo === 0 || produtos.status_promo === '0'}>
                                                            <Typography className="productPrice" variant="h6">
                                                                R$ {produtos.preco}
                                                            </Typography>
                                                        </If>
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Typography gutterBottom className="productTitle" variant="subtitle1" color="secondary" component="h3" noWrap>
                                                            {produtos.titulo}
                                                        </Typography>
                                                        <If condition={produtos.status_promo === 1 || produtos.status_promo === '1'}>
                                                            <Typography className="productPrice" variant="subtitle1" color="secondary">
                                                                <span className="oldPrice">R$ {produtos.preco}</span>
                                                        R$ {produtos.preco_promo}
                                                            </Typography>
                                                        </If>
                                                        <If condition={produtos.status_promo === 0 || produtos.status_promo === '0'}>
                                                            <Typography className="productPrice" variant="subtitle1" color="secondary">
                                                                R$ {produtos.preco}
                                                            </Typography>
                                                        </If>
                                                    </MobileView>
                                                </CardContent>
                                            </CardActionArea>
                                        </Link>
                                        <CardActions>
                                            <Grid spacing={1} container>
                                                <Grid item xs={12} lg={5}>
                                                    <Button className={classes.buttonProduct1} color="secondary" variant='contained'>
                                                        <Link to={'/produto/' + produtos.id} >Ver mais</Link>
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12} lg={7}>
                                                    <Button className={classes.buttonProduct1} color="primary" variant='contained' onClick={() => this.addCarrinho(produtos.id)}>
                                                        Add ao pedido
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            })}

                            <Grid item xs={12} lg={12} className="mt-10" >
                                <Pagination color='secondary' count={this.state.paginas} page={parseInt(localStorage.getItem('pagina')) || 1} onChange={this.pagination} />
                            </Grid>

                        </Grid>
                    </Container>

                    <Container maxWidth="lg">
                        <Snackbar open={this.state.openAlert} autoHideDuration={5000} onClose={this.handleCloseAlert}>
                            <Alert severity={this.state.alertStatus} onClose={this.handleCloseAlert}>
                                {this.state.alertMessage}
                            </Alert>
                        </Snackbar>
                    </Container>

                </main>

                <BrowserView>
                    <Footer />
                </BrowserView>

            </ThemeProvider>
        </div >
        )
    }
}


Products.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(Products);