
import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBeer, faPercentage, faQuestion, faInfo, faUser, faPlus, faHome, faNewspaper } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

export const mainListItems = (
  <div>
    <Link to={'/home'}>
        <ListItem>
      <ListItemIcon>
        <FontAwesomeIcon style={{color:'#1f3a4e'}} icon={faHome} size='lg' />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>
    </Link>
    <Link to={'/produtos'}>
    <ListItem button>
      <ListItemIcon>
        <FontAwesomeIcon style={{color:'#1f3a4e'}} icon={faBeer} size='lg' />
      </ListItemIcon>
      <ListItemText primary="Produtos" />
    </ListItem>
    </Link>
    <Link to={'/promocoes'}><ListItem button>
      <ListItemIcon>
        <FontAwesomeIcon style={{color:'#1f3a4e'}} icon={faPercentage} size='lg' />
      </ListItemIcon>
      <ListItemText primary="Promoções" />
    </ListItem></Link>
    <Link to={'/faq'}><ListItem button>
      <ListItemIcon>
        <FontAwesomeIcon style={{color:'#1f3a4e'}} icon={faQuestion} size='lg' />
      </ListItemIcon>
      <ListItemText primary="Faq" />
    </ListItem></Link>
    <Link to={'/ajuda'}><ListItem button>
      <ListItemIcon>
        <FontAwesomeIcon style={{color:'#1f3a4e'}} icon={faInfo} size='lg' />
      </ListItemIcon>
      <ListItemText primary="Ajuda" />
    </ListItem></Link>
    <a href='https://geladaemcasaapp.com.br/blog/' target="_blank" rel='noopener noreferrer'>
    <ListItem>
      <ListItemIcon>
        <FontAwesomeIcon style={{color:'#1f3a4e'}} icon={faNewspaper} size='lg' />
      </ListItemIcon>
      <ListItemText primary="Blog do Gelada" />
    </ListItem>
    </a>
    <a href='https://api.whatsapp.com/send?1=pt_BR&phone=5551992264910&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Gelada%20em%20Casa%20APP!' target="_blank">
    <ListItem>
      <ListItemIcon>
      <FontAwesomeIcon icon={faWhatsapp} style={{color:'#1f3a4e'}} size="lg" />
      </ListItemIcon>
      <ListItemText primary="Whats do Gelada" />
    </ListItem>
    </a>
    <Link to={'/login'}>
    <ListItem>
      <ListItemIcon>
        <FontAwesomeIcon style={{color:'#1f3a4e'}} icon={faUser} size='lg' />
      </ListItemIcon>
      <ListItemText primary="Entrar" />
    </ListItem>
    </Link>
    <Link to={'/cadastro'}>
    <ListItem>
      <ListItemIcon>
        <FontAwesomeIcon style={{color:'#1f3a4e'}} icon={faPlus} size='lg' />
      </ListItemIcon>
      <ListItemText primary="Cadastrar" />
    </ListItem>
    </Link>
    <ListItem>
      <ListItemText primary="Versão 1.0.34" /><br/>
    </ListItem>
    <ListItem>
      <ListItemText primary="Desenvolvido por DeD Studio" />
    </ListItem>
  </div>
);