
import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from '../assets/estilos/pages/home';
import {
    CssBaseline, Container, Typography, Button,
    Grid, Breadcrumbs, Snackbar
} from '@material-ui/core';
import Services from "../Services";
import PropTypes from 'prop-types';
import AppMenu from '../components/Menu';
import Footer from '../components/Footer';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { createMuiTheme, ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import {
    BrowserView,
} from "react-device-detect";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#eebf2f',
            main: '#eebf2f',
            dark: '#eebf2f',
            contrastText: '#1f3a4e',
        },
        secondary: {
            light: '#1f3a4e',
            main: '#1f3a4e',
            dark: '#1f3a4e',
            contrastText: '#fff',
        },
    },
});

class PromotionInside extends React.Component {

    state = {
        redirect: '',
        openAlert: false,
        alertMessage: '',
        alertStatus: 'info',
        logged: false,
        loadingForm: false,
        promocao: [],
    }

    componentDidMount() {
        document.getElementById("top").scroll(0, 0)
        var params = this.props.match.params;

        Services.promocaoInterna(this, params.id)
    }

    responsePromocaoInterna(response) {
        if (response.error === 'true' && response.type !== 'token_invalido') {
            this.setState({ openAlert: true })
            this.setState({ alertMessage: "Opa!" + response.msg })
            this.setState({ alertStatus: 'error' })
            return
        }

        if (response.error === 'true' && response.type === 'token_invalido') {
            localStorage.setItem('token_invalido', 'ok')
            this.setState({ redirect: '#' });
            return
        }

        if (response.success === 'true') {
            this.setState({ promocao: response })
        }
    }

    handleCloseAlert = () => {
        this.setState({ openAlert: false })
        this.setState({ alertMessage: '' })
        this.setState({ alertStatus: '' })
        window.location.reload();
    }


    render() {
        const { classes } = this.props;
        const promocao = this.state.promocao;
        if (this.state.redirect) {
            return (<Redirect to={'/' + this.state.redirect} />);
        }
        return (<div className={classes.rootLightGray} id="top" >
            <ThemeProvider theme={theme}>
                <AppMenu />
                <main>
                    <div className={classes.topSpace} />
                    <Container maxWidth="lg" className={classes.container}>
                        <Link to={'/promocoes'}><Button color="secondary" startIcon={<ArrowBackIcon />} variant='contained'>
                            Voltar
                        </Button></Link>
                    </Container>
                    <Container maxWidth="lg" className={classes.container}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to='/' >
                                Home
  </Link>
                            <Link to="/promocoes/">
                                Promoções
  </Link>
                            <Link to={"/promocao/" + promocao.id}
                                aria-current="page"
                            >
                                {promocao.titulo}
                            </Link>
                        </Breadcrumbs>
                    </Container>

                    <Container maxWidth="lg" className={classes.containerRounded}>
                        <Grid className={classes.gridProducts} container spacing={2} direction="row"
                            justify="center"
                            alignItems="center">

                            <Grid item xs={12} lg={3}></Grid>
                            <Grid item xs={12} lg={6}>
                                <img className="imgPromotion"
                                    src={promocao.foto_grande} />
                            </Grid>
                            <Grid item xs={12} lg={3}></Grid>
                            
                            <Grid item xs={12} lg={12}>

                                <Typography gutterBottom className="productTitle" variant="h4" component="h1" paragraph>
                                    {promocao.titulo}
                                </Typography>

                                <Typography variant="subtitle1" className="productDescription" paragraph>
                                    {promocao.texto}
                                </Typography>

                                <Button color="primary" variant='contained'>
                                    <Link to={'/produto/' + promocao.id_produto}> Comprar agora </Link>
                                </Button>

                            </Grid>
                        </Grid>
                    </Container>

                    <Snackbar open={this.state.openAlert} autoHideDuration={5000} onClose={this.handleCloseAlert}>
                        <Alert severity={this.state.alertStatus} onClose={this.handleCloseAlert}>
                            {this.state.alertMessage}
                        </Alert>
                    </Snackbar>

                </main>
                <BrowserView>
                    <Footer />
                </BrowserView>
            </ThemeProvider>
        </div>
        )
    }
}


PromotionInside.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(PromotionInside);